import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OkDialogComponent} from '../../ok-dialog/ok-dialog.component';
import {
    WorkflowTemplateAddCoworkerComponent
} from '../workflow-template-add-coworker/workflow-template-add-coworker.component';
import {WorkflowTemplateCreateComponent} from '../workflow-template-create/workflow-template-create.component';
import {
    WorkflowTemplateBasicEditorComponent
} from '../workflow-template-basic-editor/workflow-template-basic-editor.component';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {WfTemplateService} from '../../../services/wf-template.service';
import {WorkflowElementCoworkerComponent} from '../workflow-element-coworker/workflow-element-coworker.component';
import {CredentialsService} from '../../../services/credentials.service';
import {UserWfsService} from '../../../services/user-wfs.service';
import {ErrorDialogComponent} from '../../error-dialog/error-dialog.component';
import {
    FileUploaderIndependentEditorComponent
} from "../../file-system/file-uploader-independent-editor/file-uploader-independent-editor.component";
import {TemplateService} from "../../../services/template.service";
import {ReportingService} from "../../../services/reporting-service";


@Component({
    selector: 'app-workflow-template',
    templateUrl: './workflow-template.component.html',
    styleUrls: ['./workflow-template.component.less']
})
export class WorkflowTemplateComponent implements OnInit {

    CredentialsService: any = CredentialsService;

    @Output() isTemplateEvent = new EventEmitter<any>();

    @Input() template;
    @Input() strictAssignmentControllSelectionItems;
    @Input() strictClosingControllSelectionItems;
    @Input() contracts;
    @Input() customerSelectionItems;

    selectedCoworker;
    selected = new FormControl(0);

    wfElements = [];

    @ViewChildren('realWorkHours') realWorkHoursArray!: QueryList<ElementRef>;

    workflowMainGroupSelectionItems = [];
    timeCodeSelectionItems = [];
    typeSelectionItems = [];
    reportTypeSelectionItems = [];
    coworkerSelectionItems = [];
    statusSelectionItems: any[] = [{value: true, name: "WFE_STAT_ACTIVE"}, {value: false, name: "WFE_STAT_INACTIVE"}];
    allCoworkers = [];

    coworkers = [];
    workflow_elements = [];

    translatableTexts: any[] = [
        {value: "common_message_workflow_element_save_successful", name: ""},
        {value: "error_message_workflow_element_save_unsuccessful", name: ""},
        {value: "common_message_workflow_template_save_successful", name: ""},
        {value: "error_message_workflow_template_save_unsuccessful", name: ""},
        {value: "common_message_coworkers_assigned_to_workflow_element_save_successful", name: ""},
        {value: "error_message_coworkers_assigned_to_workflow_element_save_unsuccessful", name: ""},
        {value: "common_message_are_you_sure_you_want_to_delete_the_selected_workflow_element", name: ""},
        {value: "common_message_workflow_element_delete_successful", name: ""},
        {value: "error_message_workflow_element_delete_unsuccessful", name: ""},
        {value: "common_message_coworker_save_successful", name: ""},
        {value: "error_message_coworker_save_unsuccessful", name: ""},
        {value: "common_message_coworker_delete_successful", name: ""},
        {value: "error_message_coworker_delete_unsuccessful", name: ""}
    ];

    constructor(private dialogService: MatDialog,
                private snackBar: MatSnackBar,
                private translateService: TranslateService,
                public service: WfTemplateService,
                private userWfsService: UserWfsService,
                public templateService: TemplateService,
                private userWfService: UserWfsService,
                private reportingService: ReportingService) {
    }

    ngOnInit(): void {

        // console.log(this.template);

        if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
            this.coworkers = this.template.coworkers;
            this.template.workflow_elements.forEach((workflow_element) => {
                if (workflow_element.is_active) {
                    this.workflow_elements.push(workflow_element)
                }
            })

            this.service.getWfElementsByWfTemplate(this.template.id).then((response) => {
                if (response) {
                    if (response.type == "OK") {
                        response.payload.content.forEach((element) => {
                            if (element.is_active) {
                                this.wfElements.push(element);
                            }
                        })
                    }
                }
            })
        } else if (CredentialsService.role == 'user') {
            this.userWfsService.getWfCoworkers(this.template.id).subscribe((response) => {
                this.coworkers = response.payload.content;
            })

            this.userWfsService.getWfes(this.template.id).subscribe((response) => {
                this.workflow_elements = [];
                response.payload.content.forEach((element) => {
                    if (element.is_active) {
                        this.wfElements.push(element);
                        this.workflow_elements.push(element)
                    }
                });

                this.workflow_elements.sort((a, b) => {
                    if (a.workflow_element_group.serial_number > b.workflow_element_group.serial_number) {
                        return 1
                    }
                    if (a.workflow_element_group.serial_number < b.workflow_element_group.serial_number) {
                        return -1
                    }
                    return 0;
                });

            })

        }
        this.getAllCoworkers();

        /*this.service.getWfElements(this.template.id).subscribe((response) => {
          console.log(response)
        })*/

        this.translateService.onLangChange.subscribe((event) => {
            this.coworkerSelectionItems = [];
            this.service.listCoworkers(CredentialsService.role).subscribe((response) => {
                response.payload.content.forEach((coworker) => {
                    if (coworker.is_active && coworker.first_name != undefined) {
                        this.coworkerSelectionItems.push({name: this.createName(coworker), value: coworker.id})
                    }
                })
            })
        })

        this.service.getWfeTimes().subscribe((response) => {
            response.payload.forEach((time) => {
                if (time.is_active) {
                    this.timeCodeSelectionItems.push({value: time.sid, name: time.sid})
                }
            })
        })

        this.service.getWfeTypes().subscribe((response) => {
            response.payload.forEach((type) => {
                if (type.is_active) {
                    this.typeSelectionItems.push({value: type.sid, name: type.sid})
                }
            })
        })

        this.service.getWfElementGroups(CredentialsService.role).then((response) => {
            response.payload.forEach((group) => {
                if (group.is_active) {
                    this.workflowMainGroupSelectionItems.push({name: group.caption, value: group.id})
                }
            })
        })

        this.userWfsService.getARTypes().subscribe((response) => {
            response.payload.forEach((types) => {
                if (types.is_active) {
                    this.reportTypeSelectionItems.push({name: types.sid, value: types.sid})
                }
            })
        });

        this.translateService.stream([
            'common_message_workflow_element_save_successful',
            'error_message_workflow_element_save_unsuccessful',
            'common_message_workflow_template_save_successful',
            'error_message_workflow_template_save_unsuccessful',
            'common_message_coworkers_assigned_to_workflow_element_save_successful',
            'error_message_coworkers_assigned_to_workflow_element_save_unsuccessful',
            'common_message_workflow_element_delete_successful',
            'error_message_workflow_element_delete_unsuccessful',
            'common_message_coworker_save_successful',
            'error_message_coworker_save_unsuccessful',
            'common_message_coworker_delete_successful',
            'error_message_coworker_delete_unsuccessful'
        ]).subscribe((res: string) => {
            this.translatableTexts = [
                {
                    value: "common_message_workflow_element_save_successful",
                    name: res["common_message_workflow_element_save_successful"]
                },
                {
                    value: "error_message_workflow_element_save_unsuccessful",
                    name: res["error_message_workflow_element_save_unsuccessful"]
                },
                {
                    value: "common_message_workflow_template_save_successful",
                    name: res["common_message_workflow_template_save_successful"]
                },
                {
                    value: "error_message_workflow_template_save_unsuccessful",
                    name: res["error_message_workflow_template_save_unsuccessful"]
                },
                {
                    value: "common_message_coworkers_assigned_to_workflow_element_save_successful",
                    name: res["common_message_coworkers_assigned_to_workflow_element_save_successful"]
                },
                {
                    value: "error_message_coworkers_assigned_to_workflow_element_save_unsuccessful",
                    name: res["error_message_coworkers_assigned_to_workflow_element_save_unsuccessful"]
                },
                {
                    value: "common_message_workflow_element_delete_successful",
                    name: res["common_message_workflow_element_delete_successful"]
                },
                {
                    value: "error_message_workflow_element_delete_unsuccessful",
                    name: res["error_message_workflow_element_delete_unsuccessful"]
                },
                {
                    value: "common_message_coworker_save_successful",
                    name: res["common_message_coworker_save_successful"]
                },
                {
                    value: "error_message_coworker_save_unsuccessful",
                    name: res["error_message_coworker_save_unsuccessful"]
                },
                {
                    value: "common_message_coworker_delete_successful",
                    name: res["common_message_coworker_delete_successful"]
                },
                {
                    value: "error_message_coworker_delete_unsuccessful",
                    name: res["error_message_coworker_delete_unsuccessful"]
                }
            ];
        });

    }

    goBack() {
        this.isTemplateEvent.emit({value: false, template: this.template});
    }

    findById(selection, id) {
        if (id) {
            return selection.find(item => item.id == id);
        } else {
            return null;
        }
    }


    findSelectionItem(selection, value) {
        if (selection) {
            return selection.find(item => item.value == value);
        } else {
            return null;
        }
    }



    getTotalWorkHours(): number {
        const total = this.wfElements.reduce((sum, element) => sum + (element.estimated_work_hours || 0), 0);
        return Math.floor(total / 3600);
    }

    addWorkflowElement(element?) {
        const dialog = this.dialogService.open(WorkflowTemplateCreateComponent);
        dialog.componentInstance.workflowTemplate = this.template;
        dialog.componentInstance.workflowMainGroupSelectionItems = this.workflowMainGroupSelectionItems;
        dialog.componentInstance.typeSelectionItems = this.typeSelectionItems;
        dialog.componentInstance.coworkerSelectionItems = this.coworkerSelectionItems;
        dialog.componentInstance.timeCodeSelectionItems = this.timeCodeSelectionItems;
        if (element) {
//      alert(this.workflow_elements);
            dialog.componentInstance.workflowElement = this.workflow_elements.find((wfElem) => wfElem.id == element.id);
//      dialog.componentInstance.workflowElement = this.wfElements.find((wfElem) => wfElem.id == element.id);
        }
        dialog.afterClosed().subscribe(response => {
            if (response) {
                if (response.type == "OK") {
                    if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
                        this.service.getTemplate(this.template.id).then((response) => {
                            this.template = response.payload;
                            this.workflow_elements = [];
                            let activeWfEs = [];
                            this.template.workflow_elements?.forEach((wfElement) => {
                                if (wfElement.is_active) {
                                    activeWfEs.push(wfElement)
                                }
                            })
                            this.workflow_elements = activeWfEs;
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_save_successful").name, null, {panelClass: 'success-snackbar'})

                            this.wfElements = [];
                            this.service.getWfElements().then((response) => {
                                response.payload.content.forEach((element) => {
                                    if (element.is_active) {
                                        this.wfElements.push(element);
                                    }
                                })
                            })
                        })
                    } else if (CredentialsService.role == 'user') {
                        this.userWfsService.getWfes(this.template.id).subscribe((response) => {
                            this.wfElements = [];
                            this.workflow_elements = [];
                            response.payload.content.forEach((element) => {
                                if (element.is_active) {
                                    this.wfElements.push(element);
                                    this.workflow_elements.push(element)
                                }
                            })
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_save_successful").name, null, {panelClass: 'success-snackbar'})
                        })
                    }
                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                    let dialog = this.dialogService.open(ErrorDialogComponent);
                    dialog.componentInstance.error = response.payload;
                }
            }
        })
    }

    editWorkflowTemplate() {
        const dialog = this.dialogService.open(WorkflowTemplateBasicEditorComponent);
        dialog.componentInstance.template = this.template;
        dialog.componentInstance.customerSelectionItems = this.customerSelectionItems;
        dialog.componentInstance.reportTypeSelectionItems = this.reportTypeSelectionItems;
        dialog.componentInstance.contracts = this.contracts;
        dialog.afterClosed().subscribe(response => {
            if (response) {
                if (response.type == "OK") {
                    if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
                        this.service.getTemplate(this.template.id).then((response) => {
                            this.template = response.payload;
                            this.workflow_elements = [];
                            let activeWfEs = [];
                            this.template.workflow_elements?.forEach((wfElement) => {
                                if (wfElement.is_active) {
                                    activeWfEs.push(wfElement)
                                }
                            })
                            this.workflow_elements = activeWfEs;
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_template_save_successful").name, null, {panelClass: 'success-snackbar'})
                        })
                    } else if (CredentialsService.role == 'user') {
                        this.userWfsService.getWf(this.template.id).subscribe((response) => {
                            this.template = response.payload;
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_template_save_successful").name, null, {panelClass: 'success-snackbar'})
                        })
                    }

                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_template_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                    let dialog = this.dialogService.open(ErrorDialogComponent);
                    dialog.componentInstance.error = response.payload;
                }
            }

        })
    }

    createDateString(date) {
        return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
    }

    createName(coworker) {
        return localStorage.getItem("language") == "hu_HU" ? coworker.last_name + " " + coworker.first_name : coworker.first_name + " " + coworker.last_name
    }

    getCoworkerById(id) {
        return this.coworkerSelectionItems.find(coworker => coworker.value == id)
    }

    getAllCoworkers() {
        this.service.listCoworkers(CredentialsService.role).subscribe((response) => {
            response.payload.content.forEach((coworker) => {
                if (coworker.is_active && coworker.first_name != undefined) {
                    this.allCoworkers.push(coworker)
                    this.coworkerSelectionItems.push({name: this.createName(coworker), value: coworker.id})
                }
            })
        })
    }

    addCoworker() {
        const dialog = this.dialogService.open(WorkflowTemplateAddCoworkerComponent);
        dialog.componentInstance.coworkerSelectionItems = this.coworkerSelectionItems;
        dialog.componentInstance.workflowTemplate = this.template;
        dialog.afterClosed().subscribe(response => {
            if (response) {
                if (response.type == "OK") {
                    if (CredentialsService.role == 'owner') {
                        this.service.getTemplate(this.template.id).then((resp) => {
                            this.template = resp.payload;
                            this.coworkers = resp.payload.coworkers;
                            this.workflow_elements = [];
                            let activeWfEs = [];
                            this.template.workflow_elements?.forEach((wfElement) => {
                                if (wfElement.is_active) {
                                    activeWfEs.push(wfElement)
                                }
                            })
                            this.workflow_elements = activeWfEs;
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_save_successful").name, null, {panelClass: 'success-snackbar'})

                        })
                    } else if (CredentialsService.role == 'user') {
                        this.userWfsService.getWfCoworkers(this.template.id).subscribe((resp) => {
                            this.coworkers = resp.payload.content;
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_save_successful").name, null, {panelClass: 'success-snackbar'})
                        })
                    }
                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                    let dialog = this.dialogService.open(ErrorDialogComponent);
                    dialog.componentInstance.error = response.payload;
                }
            }
        })
    }

    uploadFile(coworker, isStated) {
        const dialog = this.dialogService.open(FileUploaderIndependentEditorComponent);
//    let folderList = this.flatten(this.folders);
//    dialog.componentInstance.folderNode = folderNode;
        dialog.componentInstance.coworkerId = coworker.id;
        dialog.componentInstance.workflowId = this.template.id;
        dialog.componentInstance.isStated = isStated;
        dialog.afterClosed().subscribe(closeResponse => {
            coworker.is_stated = true;
            coworker.is_independent = closeResponse;
        });
        /*    dialog.afterClosed().subscribe(closeResponse => {
              if (closeResponse){
                if (closeResponse.response.type == "OK") {
                  console.log("1");
                  let payload = btoa(JSON.stringify({
                    customer_id: this.customer_id ? this.customer_id : this.customer.value,
                    parent_path: '/',
                  }));
                  this.getTreeData(payload).then((response: any) => {
                    this.snackBar.open("A fájl feltöltése sikeres", null, {panelClass: 'success-snackbar'});
                    this.openUntilFirstOpened(closeResponse);
                  });
                }
                else{
                  this.snackBar.open("A fájl feltöltése sikertelen", null, {panelClass: 'error-snackbar'});
                  let dialog = this.dialogService.open(ErrorDialogComponent);
                  dialog.componentInstance.error = closeResponse.response.payload;
                }
              }

            })*/
    }

    downloadFile(coworkerId) {
        let body = {
            coworker_id: coworkerId,
            workflow_id: this.template.id
        }

        this.templateService.getIndependence(body).then(response => {
            var byteCharacters = atob(response.payload.content);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new File([byteArray], `${this.template.template_code}-template`, {type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);

            var link = document.createElement("a");
            var fileURL = URL.createObjectURL(file);
            link.href = fileURL;
            link.download = response.payload.name;
            link.click();

        })
    }

    downloadTemplateFile() {
        let locale = this.translateService.currentLang;

        let template = {
            locale: locale,
//          subtemplate_id: 0,
            subtemplate_id: this.template.contract.subtemplate_id,
            template_code: "TEMPLATE_CODE_FUGNYIL"
        }

        this.templateService.getTemplate(template).then(response => {
            var byteCharacters = atob(response.payload.content);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new File([byteArray], `${template.template_code}-template`, {type: response.payload.mime_type});

            var link = document.createElement("a");
            var fileURL = URL.createObjectURL(file);
            link.href = fileURL;
            link.download = response.payload.name;
            link.click();
//          var fileURL = URL.createObjectURL(file);
//          window.open(fileURL);
        })
    }

    deleteCoworker(selectedCoworker) {
        let temp = JSON.parse(JSON.stringify(this.template));
        if (CredentialsService.role == 'owner') {
            temp.coworker_ids = [];
            temp.coworkers.forEach((coworker) => {
                if (selectedCoworker.id != coworker.id) {
                    temp.coworker_ids.push(coworker.id)
                }
            })
            delete temp.coworkers;
        }
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('workflows_coworker-remove-confirmation').subscribe((value) => dialog.componentInstance.title = value)
        this.translateService.get('1').subscribe((value) => dialog.componentInstance.positiveButtonText = value)
        this.translateService.get('0').subscribe((value) => dialog.componentInstance.negativeButtonText = value)

        dialog.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
                if (CredentialsService.role == 'owner') {
                    this.service.modifyWfTemplate(temp).subscribe((resp) => {
                        if (resp) {
                            if (resp.type == "OK") {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_delete_successful").name, null, {panelClass: 'success-snackbar'});
                                this.selectedCoworker = null;
                                this.service.getTemplate(this.template.id).then((response) => {
                                    this.template = response.payload;
                                    this.coworkers = response.payload.coworkers;
                                    this.workflow_elements = [];
                                    let activeWfEs = [];
                                    this.template.workflow_elements?.forEach((wfElement) => {
                                        if (wfElement.is_active) {
                                            activeWfEs.push(wfElement)
                                        }
                                    })
                                    this.workflow_elements = activeWfEs;
                                })
                            } else {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                                let dialog = this.dialogService.open(ErrorDialogComponent);
                                dialog.componentInstance.error = resp.payload;
                            }
                        }

                    });
                } else if (CredentialsService.role == 'user') {
                    let body = {
                        id: this.template.id,
                        coworker_id: this.selectedCoworker.id
                    }
                    this.userWfsService.deleteWfCoworker(body).then((resp) => {
                        if (resp) {
                            if (resp.type == "OK") {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworker_delete_successful").name, null, {panelClass: 'success-snackbar'});
                                this.selectedCoworker = null;
                                this.userWfsService.getWfCoworkers(this.template.id).subscribe((response) => {
                                    this.coworkers = response.payload.content;
                                })
                            } else {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworker_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                                let dialog = this.dialogService.open(ErrorDialogComponent);
                                dialog.componentInstance.error = resp.payload;
                            }
                        }
                    })
                }
            }
        })
    }

    showCoworkersOnWorkflowElement(elem) {
        const dialog = this.dialogService.open(WorkflowElementCoworkerComponent);
        dialog.componentInstance.allCoworkers = this.coworkers;
        if (CredentialsService.role == 'owner') {
            elem.coworkers = [];
            dialog.componentInstance.wfElement = elem;
        } else {
            dialog.componentInstance.wfElement = this.wfElements.find((wfElem) => wfElem.id == elem.id);
        }
        // console.log(this.wfElements)
        dialog.afterClosed().subscribe(response => {
            if (response) {
                if (response.type == "OK") {
                    if (CredentialsService.role == 'owner') {
                        this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworkers_assigned_to_workflow_element_save_successful").name, null, {panelClass: 'success-snackbar'})
                        this.service.getTemplate(this.template.id).then((resp) => {
                            this.workflow_elements = [];
                            let activeWfEs = [];
                            resp.payload.workflow_elements.forEach((wfElement) => {
                                if (wfElement.is_active && wfElement.id != response.payload.id) {
                                    activeWfEs.push(wfElement)
                                }
                            });
                            activeWfEs.push(response.payload);
                            this.workflow_elements = activeWfEs;
                        })
                        this.wfElements = [];
                        this.service.getWfElements().then((resp) => {
                            resp.payload.content.forEach((element) => {
                                if (element.is_active && element.id != response.payload.id) {
                                    this.wfElements.push(element);
                                }
                            })
                            this.wfElements.push(response.payload);
                        })
                    } else if (CredentialsService.role == 'user') {
                        this.userWfsService.getWfes(this.template.id).subscribe((response) => {
                            this.wfElements = [];
                            this.workflow_elements = [];
                            response.payload.content.forEach((element) => {
                                if (element.is_active) {
                                    this.wfElements.push(element);
                                    this.workflow_elements.push(element)
                                }
                            });
                            this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_coworkers_assigned_to_workflow_element_save_successful").name, null, {panelClass: 'success-snackbar'});
                        })

                        /*this.userWfsService.getWfes(this.template.id).subscribe((resp) => {
                          this.workflow_elements = [];
                          resp.payload.content.forEach((element)=>{
                            if (element.is_active){
                              this.wfElements.push(element);
                              this.workflow_elements.push(element)
                            }
                          })
                        })*/
                    }
                } else {
                    this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_coworkers_assigned_to_workflow_element_save_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                    let dialog = this.dialogService.open(ErrorDialogComponent);
                    dialog.componentInstance.error = response.payload;
                }
            }

        })
    }

    deleteWorkflowElement(id) {
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('common_message_are_you_sure_you_want_to_delete_the_workflow_element').subscribe((value) => dialog.componentInstance.title = value)
        this.translateService.get('1').subscribe((value) => dialog.componentInstance.positiveButtonText = value)
        this.translateService.get('0').subscribe((value) => dialog.componentInstance.negativeButtonText = value)

        dialog.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
                if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
                    this.service.deleteWfElement(id).then((resp) => {
                        if (resp) {
                            if (resp.type == "OK") {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_delete_successful").name, null, {panelClass: 'success-snackbar'});
                                this.service.getTemplate(this.template.id).then((response) => {
                                    this.workflow_elements = [];
                                    this.template = response.payload;
                                    let activeWfEs = [];
                                    this.template.workflow_elements?.forEach((wfElement) => {
                                        if (wfElement.is_active) {
                                            activeWfEs.push(wfElement)
                                        }
                                    })
                                    this.workflow_elements = activeWfEs;
                                })
                            } else {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                                let dialog = this.dialogService.open(ErrorDialogComponent);
                                dialog.componentInstance.error = resp.payload;
                            }
                        }


                    });
                } else if (CredentialsService.role == 'user') {
                    this.userWfsService.deleteWfElement(id).then((resp) => {
                        if (resp) {
                            if (resp.type == "OK") {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "common_message_workflow_element_delete_successful").name, null, {panelClass: 'success-snackbar'});
                                this.userWfsService.getWfes(this.template.id).subscribe((response) => {
                                    this.workflow_elements = [];
                                    response.payload.content.forEach((element) => {
                                        if (element.is_active) {
                                            this.wfElements.push(element);
                                            this.workflow_elements.push(element)
                                        }
                                    })
                                })
                            } else {
                                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_workflow_element_delete_unsuccessful").name, null, {panelClass: 'error-snackbar'});
                                let dialog = this.dialogService.open(ErrorDialogComponent);
                                dialog.componentInstance.error = resp.payload;
                            }
                        }

                    })
                }
            }
        })
    }

    saveWorkHour(wfeid, param) {

        console.log("P:" + param);
        console.log("ID:" + wfeid);
        this.userWfService.putWorkHour(wfeid, param).subscribe((response) => {
            if (response) {
                if (response.type != "OK") {
                    console.log("PWH error:",response)
                }
            }
        });
    }

    onMyBlur = (event: FocusEvent, wfe, i): void => {
        const inputElement = event.target as HTMLInputElement;
        console.log('Input lost focus ', inputElement);
        if (inputElement.value && inputElement.value != "") {
            this.saveWorkHour(wfe.id, inputElement.value);
            this.wfElements[i].estimated_work_hours = parseInt(inputElement.value)*3600;
        }
    }
    onMyKeyDown(event: KeyboardEvent, index: number): void {
        if (event.key === 'Enter') {
            event.preventDefault();
            const inputArray = this.realWorkHoursArray.toArray();
            const nextIndex = index + 1;

            if (nextIndex < inputArray.length) {
                inputArray[nextIndex].nativeElement.focus();
            }
        }
    }
    listOfElement() {
        let body = {
            workflow_id: this.template.id,
            locale: localStorage.getItem("language"),
        }
        this.reportingService.getReportRX(body,"wfelist").subscribe((response)=>{
            this.handleFileContent(response)
        })
    }

    handleFileContent(response: { type: string; payload: { content: string; mime_type: any; name: string; }; }){
        if(response){
            if(response.type == "OK"){
                if(response.payload.content){
                    let byteCharacters = atob(response.payload.content);
                    let byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let file = new File([byteArray], `Report`, { type: response.payload.mime_type});

                    let link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    //'data:'+response.payload.mime_type+',' + byteArray;
                    link.download = response.payload.name;
                    link.click();

                }else{
                    this.snackBar.open("A megadott feltételekkel nincs adat az adatbázisban!", null, {panelClass: 'success-snackbar'});
                }
            }
        }
    }
}
