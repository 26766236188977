<mat-toolbar color="primary">
    <div>
        <button mat-icon-button matTooltip="{{'button_title_back' | translate}}" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_templates_generic-data-modification' | translate}}" (click)="editWorkflowTemplate()" *ngIf="selected.value==0">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_templates_creation' | translate}}" (click)="addWorkflowElement()" *ngIf="selected.value==2">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_templates_wfelist' | translate}}" (click)="listOfElement()" *ngIf="selected.value==2">
            <mat-icon>file_open</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_templates_coworker-attribution' | translate}}" (click)="addCoworker()" *ngIf="selected.value==1">
            <mat-icon>person_add</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'workflow_templates_coworker-remove' | translate}}" [disabled]="!selectedCoworker" (click)="deleteCoworker(selectedCoworker)" *ngIf="selected.value==1">
            <mat-icon>person_remove</mat-icon>
        </button>
    </div>
    <div style="width:100%">
        <h2 mat-dialog-title>{{findSelectionItem(customerSelectionItems, findById(contracts, template.contract_id)?.customer_id)?.name}}, {{template.caption}}</h2>
    </div>
</mat-toolbar>

<mat-tab-group class="tab-bar" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms">
    <mat-tab label="{{'workflows_generic-datas' | translate}}">

        <div class="table-content" id="generic-data" [class.owner]="CredentialsService.role == 'owner'">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.justify-content]="'flex-end'" [style.width]="'50%'">
                        {{'workflows_generic-data-name' | translate}}
                    </div>
                    <div class="table-text" [style.justify-content]="'flex-start'" [style.width]="'50%'">
                        {{'workflows_value' | translate}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_cleint' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' ? findSelectionItem(customerSelectionItems, findById(contracts, template.contract_id)?.customer_id).name : ''}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_contract' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' ? findById(contracts, template.contract_id)?.contract_code : ""}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_business-year-start' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' && template.start_of_business_year ? createDateString(template.start_of_business_year * 1000) : ''}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_business-year-end' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' && template.end_of_business_year ? createDateString(template.end_of_business_year * 1000) : ''}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{CredentialsService.role == 'user' ? ('workflows_report-format' | translate) : ('workflow_templates_label' | translate)}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' ? (template.report_type_sid | translate) : template.caption}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_inspection-close-date' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' && template.audit_closing_date ? createDateString(template.audit_closing_date * 1000) : ''}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_audit-report-issue-date' | translate}}
                    </div>
                    <div class="table-text">
                        {{CredentialsService.role == 'user' && template.audit_report_date ? createDateString(template.audit_report_date * 1000) : ''}}
                    </div>
                </div>
                <!--<div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflow_templates_audit-team-setup-controll' | translate}}
                    </div>
                    <div class="table-text" *ngIf="findSelectionItem(strictAssignmentControllSelectionItems, template.is_strict_assignment_control).name == 'No'">
                        {{'0' | translate}}
                    </div>
                    <div class="table-text" *ngIf="findSelectionItem(strictAssignmentControllSelectionItems, template.is_strict_assignment_control).name == 'Yes'">
                        {{'1' | translate}}
                    </div>
                </div>-->
                <div [style.display]="'flex'" class="table-item">
                    <div class="table-text">
                        {{'workflow_templates_mandatory-close-controll' | translate}}
                    </div>
                    <div class="table-text" *ngIf="findSelectionItem(strictClosingControllSelectionItems, template.is_strict_closure_control).name == 'No'">
                        {{'0' | translate}}
                    </div>
                    <div class="table-text" *ngIf="findSelectionItem(strictClosingControllSelectionItems, template.is_strict_closure_control).name == 'Yes'">
                        {{'1' | translate}}
                    </div>
                </div>
                <div [style.display]="'flex'" class="disabled table-item">
                    <div class="table-text">
                        {{'workflow_templates_status' | translate}}
                    </div>
                    <div class="table-text" *ngIf="CredentialsService.role == 'user'">
                        {{template.workflow_status_sid | translate}}
                    </div>
                    <div class="table-text" *ngIf="CredentialsService.role != 'user'">
                    </div>
                </div>
            </div>
        </div>

    </mat-tab>
    <mat-tab label="{{'workflow_templates_coworker' | translate}}">

        <div class="table-content" id="coworkers-data">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.width]="'40%'">
                        {{'workflow_templates_coworker' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'40%'">
                        {{'common_title_independent' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        {{'common_title_statement_management' | translate}}
                    </div>
                </div>

                <div [style.display]="'flex'" class="table-item" *ngFor="let coworker of coworkers" (click)="selectedCoworker == coworker ? selectedCoworker = null : selectedCoworker = coworker" [class.selected]="selectedCoworker == coworker">
                    <div class="table-text" [style.width]="'40%'">
                        {{getCoworkerById(coworker.id)?.name}}
                    </div>
                    <div class="table-text" [style.width]="'40%'">
                        {{coworker.is_stated ? (coworker.is_independent ? "Igen" : "Nem") : ""}}
                    </div>
                    <div class="table-text" [style.width]="'20%'">
                        <ng-container *ngIf="coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_edit_statement' | translate}}" (click)="uploadFile(coworker, coworker.is_stated)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_upload_statement' | translate}}" (click)="uploadFile(coworker, false)">
                                <mat-icon>upload</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_view_statement' | translate}}" (click)="downloadFile(coworker.id)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!coworker.is_stated">
                            <button mat-icon-button matTooltip="{{'common_title_download_template' | translate}}" (click)="downloadTemplateFile()">
                                <mat-icon>image</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="{{'workflow_templates_workflow-element' | translate}}">
        <div class="table-content" id="workflow-elements-data">
            <div class="column">
                <div class="table-header">
                    <div class="table-text" [style.width]="'15%'">
                        {{'workflow_templates_timing-code' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{'workflows_group' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'30%'">
                        {{'workflows_generic-data-name' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{'common_title_estimated_man_hours_demand' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{'common_title_status' | translate}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                    </div>
                </div>
                <div *ngFor="let element of wfElements; let i = index;" class="table-item" (click)="selectedWorkflowElement == element ? selectedWorkflowElement = null : selectedWorkflowElement = element" [class.selected]="selectedWorkflowElement == element">
                    <div class="table-text" [style.width]="'15%'">
                        {{(i == 0 ? element.workflow_element_group.workflow_element_group_time_sid : wfElements[i].workflow_element_group.workflow_element_group_time_sid != wfElements[i-1].workflow_element_group.workflow_element_group_time_sid ? element.workflow_element_group.workflow_element_group_time_sid : '') | translate }}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{
                        element.workflow_element_group.fix_code!=null ?
                        ((i == 0 ? element.workflow_element_group.fix_code : (wfElements[i].workflow_element_group.fix_code != wfElements[i-1].workflow_element_group.fix_code ? element.workflow_element_group.fix_code : "" )) | translate)
                        :
                        ((i == 0 ? element.workflow_element_group.caption : (wfElements[i].workflow_element_group.caption != wfElements[i-1].workflow_element_group.caption ? element.workflow_element_group.caption : "" )))
                        }}
                    </div>
                    <div class="table-text" [style.width]="'30%'">
                        {{element.fix_code ? (element.fix_code | translate) : element.caption}}
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        <mat-form-field  floatLabel="never">
                            <input matInput [ngModel]="element.real_work_hours | number:'1.0':language"
                                   [value]="element.real_work_hours"
                                   (blur)="onMyBlur($event,element, i)"
                                   (keydown)="onMyKeyDown($event, i)"
                                   [style.width.px]="80"
                                   #realWorkHours
                                   style="text-align: right;">
                        </mat-form-field>
                    </div>
                    <div class="table-text" [style.width]="'10%'">
                        {{findSelectionItem(statusSelectionItems, element.is_active)?.name | translate}}
                    </div>
                    <div class="table-text" [style.width]="'15%'">
                        <button mat-icon-button matTooltip="" (click)="addWorkflowElement(element)">
                            <mat-icon [style.color]="'darkgrey'">settings</mat-icon>
                        </button>
                        <!--<button mat-icon-button matTooltip="" (click)="showCoworkersOnWorkflowElement(element)">
                            <mat-icon [style.color]="'navy'">group</mat-icon>
                        </button>-->
                        <button mat-icon-button matTooltip="" (click)="deleteWorkflowElement(element.id)">
                            <mat-icon [style.color]="'red'">delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="table-item">
                    <div class="table-text" [style.width]="'55%'">
                        <!-- Üres oszlopok a 4. oszlop előtt -->
                    </div>
                    <div class="table-text" [style.width]="'10%'" style="font-weight: bold;">
                        {{ getTotalWorkHours() | number:'1.0-0' }}
                    </div>
                    <div class="table-text" [style.width]="'35%'">
                        <!-- Üres oszlopok a 4. oszlop után -->
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
